import { useEffect, useRef, useState } from "react";
import "./styles.css";

import React from "react";
import { useNotifications } from "../../utils/NotificationContext";

const Notifications = () => {
  const { notifications } = useNotifications();

  return (
    <div className={`absolute notification-wrapper no-bg`}>
      {notifications
        .map((notif, index) => (
          <FloatingNotification
            initial_lifetime={notif.timeStart}
            notification_id={notif.id}
            key={notif.id}
          >
            {notif.text}
          </FloatingNotification>
        ))
        .reverse()}
    </div>
  );
};

const FloatingNotification = ({
  initial_lifetime,
  notification_id,
  children,
}) => {
  const [timeLeft, setTimeLeft] = useState(initial_lifetime);

  const { removeNotification } = useNotifications();

  const notificationLifetimeInterval = useRef(null);
  useEffect(() => {
    notificationLifetimeInterval.current = setInterval(() => {
      setTimeLeft((t) => t - 0.05);
    }, 50);

    return () => {
      clearInterval(notificationLifetimeInterval.current);
    };
  }, []);

  useEffect(() => {
    if (timeLeft < -1) {
      removeNotification(notification_id);
    }
  }, [removeNotification, timeLeft, notification_id]);

  if (timeLeft < 0 || children === undefined) return <></>;
  return (
    <div className={`z-top-3 ${timeLeft <= 1 ? "disappearing" : ""}`}>
      {children}
      <div
        className="absolute z-top-3"
        style={{
          right:
            "calc(" + (100 - timeLeft * (100 / initial_lifetime)) + "% + 13px)",
        }}
      />

      <div className="dismiss-button no-bg">
        <img
          src="/assets/images/clear_small.png"
          alt="Dismiss button"
          onClick={() => {
            if (timeLeft > 1) setTimeLeft(1);
          }}
        />
      </div>
    </div>
  );
};

export { Notifications, FloatingNotification };
