// ProtectedRoute.js

import { Outlet, useLocation } from "react-router-dom";
import { isLoggedIn } from "./API/auth.js";
import CustomNavigate from "./customNavigateAnchor.js";

function isStandalone(location) {
  // For iOS
  if (
    window.navigator.standalone !== undefined &&
    window.navigator.standalone == true
  ) {
    return true;
  }

  // For Android and other platforms
  if (window.matchMedia("(display-mode: standalone)").matches) {
    return true;
  }

  // Additional checks
  if (window.navigator.userAgent.toLowerCase().includes("wv")) {
    // Check for WebView
    return false;
  }

  if (document.referrer.startsWith("android-app://")) {
    // Check for Android TWA (Trusted Web Activity)
    return true;
  }

  // Check if display mode is fullscreen or minimal-ui
  if (
    window.matchMedia("(display-mode: fullscreen)").matches ||
    window.matchMedia("(display-mode: minimal-ui)").matches
  ) {
    return true;
  }

  const queryParams = new URLSearchParams(location.search);

  // Get a specific query parameter, e.g., 'source'
  const source = queryParams.get("source");
  // Check if we have the source param in the url
  if (source == "pwa") {
    return true;
  }

  return false;
}
const ProtectedRoute = ({ protection }) => {
  let protections = protection.split(" ");
  let location = useLocation();

  if (process.env.REACT_APP_ALLOW_BROWSER !== "true") {
    if (!protections.find((el) => el === "allow_browser")) {
      if (!isStandalone(location))
        return <CustomNavigate to="/go-install" replace />;
    }

    if (protections.find((el) => el === "prevent_app")) {
      if (isStandalone(location)) return <CustomNavigate to="/hello" replace />;
    }
  }

  if (!protections.find((el) => el === "allow_pc")) {
    if (!/iPhone|iPad|iPod|Android/i.test(navigator.userAgent))
      return <CustomNavigate to="/go-mobile" replace />;
  }

  if (protections.find((el) => el === "prevent_phone")) {
    if (/iPhone|iPad|iPod|Android/i.test(navigator.userAgent))
      return <CustomNavigate to="/hello" replace />;
  }

  if (protections.find((el) => el === "login")) {
    if (!isLoggedIn()) return <CustomNavigate to="/hello" replace />;
  }

  if (protections.find((el) => el === "guest")) {
    if (isLoggedIn()) return <CustomNavigate to="/" replace />;
  }

  return <Outlet />;
};

export default ProtectedRoute;
