import React, { createContext, useState, useEffect, useContext } from "react";
import { useLocation } from "react-router-dom";
import { onMessageListener, requestForToken } from "./firebase";
import { UpdateFCMToken, isLoggedIn } from "./API/auth";
import { getCookie } from "./cookie";

export const NotificationContext = createContext();

export const useNotifications = () => {
  return useContext(NotificationContext);
};

export const NotificationProvider = ({ children }) => {
  const [notifications, setNotifications] = useState([]);
  const location = useLocation();

  const [fcmToken, setFcmToken] = useState(null);

  useEffect(() => {
    const storedNotifications = localStorage.getItem("BTK-notifications");
    if (storedNotifications) {
      setNotifications(JSON.parse(storedNotifications));
    }
  }, [location]);

  useEffect(() => {
    localStorage.setItem("BTK-notifications", JSON.stringify(notifications));
  }, [notifications]);

  useEffect(() => {
    (async function getToken() {
      const token = await requestForToken();

      if (!token) {
        setFcmToken(null);
        localStorage.removeItem("BTK-fcmToken");

        return;
      }
      setFcmToken(token);
      localStorage.setItem("BTK-fcmToken", token);
      if (isLoggedIn()) await UpdateFCMToken(token);
    })();
  }, []);

  onMessageListener()
    .then((payload) => {
      if (payload.notification && payload.notification.body)
        addNotification(payload.notification.body);
    })
    .catch((err) => {
      console.log("failed: ", err);
      if (getCookie("is_debug_mode") == "true") {
        alert(JSON.stringify(err));
      }
    });

  const addNotification = (message, timeStart = 10) => {
    setNotifications([
      ...notifications,
      { text: message, id: Date.now(), timeStart: timeStart },
    ]);
  };

  const removeNotification = (id) => {
    let newNotifications = [...notifications];
    newNotifications = newNotifications.filter((notif) => notif.id !== id);
    setNotifications(newNotifications);
  };

  return (
    <NotificationContext.Provider
      value={{
        notifications,
        addNotification,
        removeNotification,
      }}
    >
      {children}
    </NotificationContext.Provider>
  );
};
