import CustomButton from "../../components/Buttons";
import PageTitle from "../../components/PageTitle";
import useCustomNavigate from "../../utils/CustomNavigate";
import CustomLink from "../../utils/customLink";

import "./styles.css";

const HelloPage = () => {
  const navigator = useCustomNavigate();

  return (
    <>
      <PageTitle dimension="medium">
        Ready to discover <br />
        healthy <br />
        micro-habits?
      </PageTitle>

      <CustomButton
        variant="primary"
        className="absolute authenticate-button"
        onClick={() => navigator("/register")}
      >
        Sign Up
      </CustomButton>

      <div className="absolute register-wrapper">
        <p>Already have an account?</p>
        <CustomLink to="/login">Log in with your work e-mail!</CustomLink>
      </div>

      <div className={`absolute authenticate-mascot`}>
        <img src="/assets/images/mascot-apple.png" alt="The BioTweak Mascot" />
      </div>
    </>
  );
};

export default HelloPage;
