import PageTitle from "../PageTitle";
import { isNextDay, isNextWeekday } from "../../utils/time";

import "./styles.css";

const ProgressCalendar = ({ calendar, type = 7 }) => {
  if (calendar === null) {
    return <PageTitle dimension="big">Loading</PageTitle>;
  }

  if (type === 5) {
    return <ProgressCalendar5 calendar={calendar} />;
  }
  if (type === 7) {
    return <ProgressCalendar7 weeks={calendar} />;
  }
  return <PageTitle dimension="big">Not found...</PageTitle>;
};

function formatDateJSX(dateString) {
  const months = [
    "Jan",
    "Feb",
    "Mar",
    "Apr",
    "May",
    "Jun",
    "Jul",
    "Aug",
    "Sep",
    "Oct",
    "Nov",
    "Dec",
  ];
  const date = new Date(dateString);
  const day = date.getDate();
  const month = months[date.getMonth()];
  return (
    <>
      {day}
      <span className="month">&nbsp;{month}</span>
    </>
  );
}

function cellContentWithFeedback(calendarElement) {
  let feedback = calendarElement.feedback;
  return (
    <img
      className="feedback"
      src={`/assets/images/rating/rating-calendar${feedback}.svg`}
      alt={`Rating value ${feedback}/4`}
    />
  );
}

const ProgressCalendar5 = ({ calendar }) => {
  const handleClickChallenge = (id) => {};
  let calendarJSX = calendar.map((el, index) => {
    let weeks = ["-", "M", "T", "W", "Th", "F"];

    let calendarElementText = formatDateJSX(el.date);

    if (el.feedback) {
      calendarElementText = cellContentWithFeedback(el);
    }
    if (isNextWeekday(el.date)) {
      calendarElementText = "Up next!";
    }
    if (el.date === new Date().toISOString().split("T")[0] && !el.completed) {
      calendarElementText = "Now!";
    }
    if (el.high_streak_end && el.streak > 1) {
      calendarElementText = "Best!";
    }

    return (
      <div
        className={`calendar-element ${el.completed ? "active" : ""} ${
          el.high_streak ? "streak" : ""
        }`}
        key={index}
        onClick={() => {
          if (el.id) handleClickChallenge(el.id);
        }}
      >
        {calendarElementText}
      </div>
    );
  });

  return (
    <>
      <div className="calendar-wrapper-5">{calendarJSX}</div>
    </>
  );
};

const ProgressCalendar7 = ({ weeks }) => {
  const handleClickChallenge = (id) => {};

  let calendarJSX = weeks.map((el, index) => {
    let subJSX = el.map((el2, index) => {
      let calendarElementText = formatDateJSX(el2.date);

      if (el2.feedback) {
        calendarElementText = cellContentWithFeedback(el2);
      }
      if (isNextDay(el2.date)) {
        calendarElementText = "Up next!";
      }
      if (
        el2.date === new Date().toISOString().split("T")[0] &&
        !el2.completed
      ) {
        calendarElementText = "Now!";
      }
      if (el2.high_streak_end && el2.streak > 1) {
        calendarElementText = "Best!";
      }

      return (
        <div
          className={`calendar-element ${el2.completed ? "active" : ""} ${
            el2.high_streak ? "streak" : ""
          }`}
          key={index}
          onClick={() => {
            if (el2.id) handleClickChallenge(el2.id);
          }}
        >
          {calendarElementText}
        </div>
      );
    });

    return (
      <div className="group" key={index}>
        {subJSX}
      </div>
    );
  });

  return (
    <>
      <div className="calendar-wrapper-7">{calendarJSX}</div>
    </>
  );
};

export default ProgressCalendar;
