import React, { useEffect } from "react";
import "./App.css";

import { BrowserRouter as Router, Route, Routes } from "react-router-dom";

import ChallengePage from "./screens/ChallengePage";
import ProgressPage from "./screens/ProgressPage";
import LearningPage from "./screens/LearningPage";

import SettingsPage from "./screens/SettingsPage";
import AccountPage from "./screens/AccountPage";
import AccountDeletePage from "./screens/AccountDeletePage";

import FeedbackPage from "./screens/FeedbackPage";
import PostFeedbackPage from "./screens/PostFeedbackPage";

// src/index.js or src/App.js
import "bootstrap/dist/css/bootstrap.min.css";
import ProtectedRoute from "./utils/ProtectedRoute";
import ChallengeInfoPage from "./screens/ChallengeInfoPage";
import LearnArticlePage from "./screens/LearnArticlePage";
import HelloPage from "./screens/HelloPage";
import RegisterPage from "./screens/RegisterPage";
import LoginPage from "./screens/LoginPage";
import { Notifications } from "./components/FloatingNotification";
import { NotificationProvider } from "./utils/NotificationContext";
import InstallPage from "./screens/InstallPage";
import UserCongratulationsPage from "./screens/UserCongratulationsPage";
import ChallengeCompletedByPage from "./screens/ChallengeCompletedByPage";
import ChallengeSettingsPage from "./screens/ChallengeSettingsPage";
import AuthenticateVerifyPage from "./screens/AuthenticateVerifyPage";
import LoginSendToken from "./screens/LoginSendToken";
import RegisterSendToken from "./screens/RegisterSendToken";
import RegisterLocationPage from "./screens/RegisterLocationPage";
import LegalPage from "./screens/LegalPage";
import AboutPage from "./screens/AboutPage";
import useOnlineStatus from "./utils/onlineStatus";
import { OfflineNotification } from "./components/StaticNotification";
import CustomNavigate from "./utils/customNavigateAnchor";

function App() {
  const isOnline = useOnlineStatus();

  return (
    <>
      <Router>
        <NotificationProvider>
          <Routes>
            <Route
              path="/go-mobile"
              element={
                <ProtectedRoute protection="allow_pc prevent_phone allow_browser" />
              }
            >
              <Route index element={<InstallPage />} />
            </Route>

            <Route
              path="/go-install"
              element={
                <ProtectedRoute protection="allow_browser prevent_app allow_pc" />
              }
            >
              <Route index element={<InstallPage />} />
            </Route>

            <Route path="/about/tc" element={<ProtectedRoute protection="" />}>
              <Route index element={<LegalPage />} />
            </Route>

            <Route
              path="/hello"
              element={<ProtectedRoute protection="guest" />}
            >
              <Route index element={<HelloPage />} />
              <Route path="verify" element={<AuthenticateVerifyPage />} />
            </Route>

            <Route
              path="/register"
              element={<ProtectedRoute protection="guest" />}
            >
              <Route path="send" element={<RegisterSendToken />} />
              <Route path="location" element={<RegisterLocationPage />} />
              <Route index element={<RegisterPage />} />
            </Route>

            <Route
              path="/login"
              element={<ProtectedRoute protection="guest" />}
            >
              <Route path="send" element={<LoginSendToken />} />
              <Route index element={<LoginPage />} />
            </Route>

            <Route path="/home" element={<ProtectedRoute protection="login" />}>
              <Route index element={<ChallengePage />} />
            </Route>

            <Route
              path="/about"
              element={<ProtectedRoute protection="login" />}
            >
              <Route index element={<AboutPage />} />
            </Route>

            <Route
              path="/challenge"
              element={<ProtectedRoute protection="login" />}
            >
              <Route index element={<ChallengePage />} />
              <Route path="info" element={<ChallengeInfoPage />} />
              <Route
                path="completed-by"
                element={<ChallengeCompletedByPage />}
              />
              <Route path="settings" element={<ChallengeSettingsPage />} />
            </Route>

            <Route
              path="/learning"
              element={<ProtectedRoute protection="login" />}
            >
              <Route index element={<LearningPage />} />
              <Route path=":id" element={<LearnArticlePage />} />
            </Route>

            <Route
              path="/progress"
              element={<ProtectedRoute protection="login" />}
            >
              <Route index element={<ProgressPage />} />
              <Route
                path="notifications"
                element={<UserCongratulationsPage />}
              />
            </Route>

            <Route
              path="/settings"
              element={<ProtectedRoute protection="login" />}
            >
              <Route index element={<SettingsPage />} />
            </Route>

            <Route
              path="/account"
              element={<ProtectedRoute protection="login" />}
            >
              <Route index element={<AccountPage />} />

              <Route path="delete" element={<AccountDeletePage />} />
            </Route>

            <Route
              path="/feedback"
              element={<ProtectedRoute protection="login" />}
            >
              <Route index element={<FeedbackPage />} />

              <Route path="thank-you" element={<PostFeedbackPage />} />
            </Route>

            <Route path="/" element={<ProtectedRoute protection="login" />}>
              <Route index element={<ChallengePage />} />
            </Route>

            <Route
              path="*"
              element={<CustomNavigate to="/" replace={true} />}
            />
          </Routes>

          <Notifications />

          <OfflineNotification show={!isOnline} />
        </NotificationProvider>
      </Router>
    </>
  );
}

export default App;
