import { useState } from "react";
import CustomButton from "../../components/Buttons";
import FormInputText from "../../components/FormInputText";
import PageTitle from "../../components/PageTitle";

import "./styles.css";
import { handleRequestCode, testEmailExists } from "../../utils/API/auth";
import useGetMascot from "../../utils/GetRandomMascot";
import { useNotifications } from "../../utils/NotificationContext";
import { setNotification } from "../../utils/Notifications";
import TopMenu from "../../components/TopMenu";
import useCustomNavigate from "../../utils/CustomNavigate";

const LoginPage = () => {
  const [email, setEmail] = useState("");
  const [buttonSendLinkLoading, setButtonSendLinkLoading] = useState("");

  const navigator = useCustomNavigate();
  const mascot = useGetMascot(0);

  const { addNotification } = useNotifications();

  const handleSendCode = async () => {
    setButtonSendLinkLoading(true);
    try {
      let testResult = await testEmailExists(email);

      if (testResult.code !== 200) {
        setButtonSendLinkLoading(false);
        addNotification(testResult.message);
        return;
      }

      if (testResult.registered === false) {
        setNotification("You do not have an account. Please register!");
        navigator(`/register`, {
          state: {
            pre_completed_email: email,
          },
        });
        return;
      }

      let requestResult = await handleRequestCode(email);

      if (requestResult.code !== 200) {
        setButtonSendLinkLoading(false);
        let message = requestResult.message;
        addNotification(message);
        return;
      }

      setButtonSendLinkLoading(false);

      if (requestResult.type === "login") {
        setNotification("E-mail sent!");
        navigator(`/hello/verify`, {
          state: {
            type: "login",
            email: email,
          },
        });
        return;
      }
      if (requestResult.type === "register") {
        setNotification("You do not have an account. Please register!");
        navigator(`/register`);
        return;
      }

      setNotification("An error has occured...");
      navigator("/");

      return;
    } catch (err) {
      setButtonSendLinkLoading(false);
      addNotification(err);
    }
  };

  return (
    <>
      <TopMenu backLink="hello" showMenu={false} />
      <PageTitle dimension="medium">Log-in with your Work E-mail</PageTitle>

      <form
        className="absolute login-send-form-wrapper"
        onSubmit={(e) => e.preventDefault()}
      >
        <FormInputText
          name="email"
          id="email"
          onInput={(e) => setEmail(e.target.value)}
          value={email}
          className="w-100 mt-2"
        >
          Work e-mail
        </FormInputText>
      </form>
      <CustomButton
        variant="primary"
        className="absolute login-send-password-button"
        onClick={handleSendCode}
        disabled={email.length === 0 || buttonSendLinkLoading}
      >
        {buttonSendLinkLoading ? "Loading..." : "Send me a password"}
      </CustomButton>
      <div className={`absolute authenticate-mascot `}>
        <img src={mascot} alt="The BioTweak Mascot" />
      </div>
    </>
  );
};

export default LoginPage;
